import { initNav } from './headerNav'
import { watchScrollVisible } from './scrollVisible'
import './scrollSmooth'
import './homeSlide'
import './sidebar'
import './headerNavMobile'
import googleMapsEmbed from '../js-lib/googleMapsEmbed'

initNav()
watchScrollVisible()
googleMapsEmbed()
